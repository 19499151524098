import eventsHandler from '@/services/common/eventsHandler.js'

class paymentConditionForm extends eventsHandler {
  constructor() {
    super()
    this._paymentCondition = {}
    this._valid = false
  }

  get paymentCondition() {
    const paymentCondition = {
      ...this._paymentCondition
    }
    return paymentCondition
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._paymentCondition.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetPaymentCondition() {
    this._paymentCondition = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {paymentCondition: {}})
  }

  updateField(key, value) {
    this._paymentCondition[key] = value
    this._executeCallbacksOf('update', {paymentCondition: this._paymentCondition})
    this._executeCallbacksOf('update-' + key, {key: this._paymentCondition[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(paymentCondition) {
    this._paymentCondition = paymentCondition
    this._executeCallbacksOf('update', {paymentCondition: this._paymentCondition})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new paymentConditionForm()